import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { getFeedback, submitFeedback } from 'API/feedback'
import { exportToCsv } from '../../../../admin/course/[id]'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import FeedbackInvite from '../../../../../components/feedbacklink'
const FeedbackView: React.FC<PageProps> = ({ params: { id, feedback_id } }) => {
	const { ShowDetails, viewAs, course } = useGetCourseDetails(id)
	const [feedback, setFeedback] = useState<{
		feedback: { heading: string; type: string; questions: string[] }
		submissions: { answers: number[]; length: undefined } | { answers: number[] }[]
		submission_list: any[]
	}>()
	const [answers, setAnswers] = useState<number[]>([])
	const [additionalComment, setAdditionalComment] = useState('')
	const [sum, setSum] = useState(0)

	useEffect(() => {
		if (feedback_id) {
			const run = async () => {
				const feedback = await getFeedback({ feedback_id, viewAs })
				if (feedback) {
					setFeedback(feedback)

					if (viewAs === 'Student') {
						if (!feedback.submissions) {
							setAnswers(Array.from({ length: feedback.feedback.questions.length }, () => 0))
						} else {
							return setAnswers(((feedback.submissions as { answers: number[] })?.answers).map((elem) => parseInt(elem.toString())))
						}
					}

					if (viewAs !== 'Student') {
						setAnswers((feedback.submissions as { answers: number[] }[]).map((elem) => elem.answers.reduce((acc, curr) => acc + curr, 0)))
					}
				}
			}
			run()
		}
	}, [feedback_id, viewAs])
	console.log(feedback, answers)
	useEffect(() => {
		const run = () => {
			const sum = answers.reduce((a, b) => a + b, 0)
			setSum(sum)
		}
		run()
	}, [answers.length !== 0])

	return (
		<>
			{viewAs !== 'Student' && course && <FeedbackInvite feedback_id={feedback_id} course={course.course.uid} />}
			{ShowDetails}
			{/*  */}

			<h3>
				Topic {'  '}: {feedback?.feedback.heading}
			</h3>
			<h3>Marker: {feedback?.feedback.type}</h3>
			{viewAs === 'Student' &&
				answers?.length > 0 &&
				!feedback?.submissions &&
				feedback?.feedback.questions.map((question, index) => (
					<>
						<div style={{ padding: '10px', fontWeight: 700 }} className='' key={index}>
							<h3>
								{index + 1}. {question}
							</h3>
							<>
								<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }} className=''>
									<div className=''>Excellent</div>
									<div className='answers' style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
										{[5, 4, 3, 2, 1].map((elem) => (
											<>
												<Form
													// onChange={(e) => {
													// 	if (e) {
													// 		console.log(e)
													// 		const new_answers = answers
													// new_answers[index] = elem
													// 		setAnswers(() => [...new_answers])
													// 	}
													// }}
													style={{ padding: '0 1rem' }}
													// className=''
												>
													<Form.Check
														type='radio'
														name=''
														value={elem}
														checked={elem === answers[index]}
														id=''
														onChange={(e) => {
															if (e) {
																// console.log(e)
																const new_answers = answers
																new_answers[index] = elem
																setAnswers(() => [...new_answers])
															}
														}}
														required
													/>
												</Form>
											</>
										))}
										{[5, 4, 3, 2, 1].map((elem) => (
											<>
												<Form
													onChange={(e) => {
														if (e) {
															// console.log(e)
															const new_answers = answers
															new_answers[index] = elem
															setAnswers(() => [...new_answers])
														}
													}}
													style={{ padding: '0 1rem' }}
													className=''
												>
													{elem}
												</Form>
											</>
										))}
									</div>
									<div className=''>Poor</div>
								</div>
							</>
						</div>

						<hr />
					</>
				))}

			{viewAs === 'Student' && feedback?.submissions && (
				<>
					<div>Feedback Submitted</div>
				</>
			)}
			{viewAs === 'Student' && !feedback?.submissions && (
				<>
					<div style={{ padding: '10px', fontWeight: 700 }} className=''>
						<h3>Do you want revisions and/or improvements on any particular topic?</h3>
					</div>
					<textarea
						onChange={(e) => {
							setAdditionalComment(e.target.value)
						}}
					></textarea>
					<br />
				</>
			)}
			{viewAs === 'Student' &&
				!feedback?.submissions &&
				(answers[0] === 0 ||
					answers[1] === 0 ||
					answers[2] === 0 ||
					answers[3] === 0 ||
					answers[4] === 0 ||
					answers[answers.length - 2] === 0 ||
					answers[answers.length - 1] === 0) && (
					<>
						<button type='submit' disabled className='btn btn-primary'>
							Submit
						</button>
					</>
				)}
			{viewAs === 'Student' &&
				!feedback?.submissions &&
				answers[0] != 0 &&
				answers[1] != 0 &&
				answers[2] != 0 &&
				answers[3] != 0 &&
				answers[4] != 0 &&
				answers[answers.length - 2] != 0 &&
				answers[answers.length - 1] != 0 && (
					<>
						<button
							type='submit'
							onClick={async () => {
								if (course) {
									await submitFeedback({
										answers,
										course_uid: course.course.uid,
										feedback_uid: feedback_id,
										additionalComment: additionalComment,
									})
									toast('Form Saved', { type: 'success' })
									setTimeout(() => {
										window.location.reload()
									}, 3000)
								} else {
									toast('Complete The Form', { type: 'error' })
								}
							}}
							className='btn btn-primary'
						>
							Submit
						</button>
					</>
				)}

			{answers.length !== 0 && viewAs === 'Teacher' && feedback?.submissions?.length !== undefined && (
				<>
					<div
						onClick={() => {
							const questions = [...feedback.feedback.questions, 'Weighted Average']
							// const aver = ['Weighted Average --->' + (sum / answers.length / 10).toFixed(2)]
							const answers_down = (
								feedback.submissions as {
									answers: number[]
								}[]
							).map((elem) => {
								const answer_inner = elem.answers

								answer_inner.push(answer_inner.reduce((a, b) => a + b, 0) / 7)

								return answer_inner
							})
							const data_copy = [...answers_down]
							console.log(data_copy)
							const transpose = (m: any[][]) => m[0].map((x, i) => m.map((x) => x[i]))
							console.log(transpose(data_copy))
							const data = transpose(data_copy)
							const result = data_copy.reduce((r: any, a: any) => a.map((b: number, idx: number) => (r[idx] || 0) + b, []))
							console.log(result)
							const avg = result.map((elem, idx) => {
								result[idx] = parseFloat((elem / data_copy.length).toFixed(2))
							})

							const report = [questions, ...answers_down, result]

							exportToCsv(`report_${feedback.feedback.heading}_question_wise.csv`, transpose(report))
						}}
						className='btn btn-primary download'
					>
						Download Report Question Wise
					</div>
					<div
						onClick={() => {
							const student_name_list = (feedback.submission_list as { first_name: string; last_name: string }[]).map((elem) => {
								const name = `${elem.first_name} ${elem.last_name}`
								return name
							})
							const student_roll_list = (feedback.submission_list as { username: string }[]).map((elem) => {
								const roll_num = elem.username
								return roll_num
							})
							const student_additional_comm_list = (feedback.submission_list as { additionalComment: string }[]).map((elem) => {
								const add_comm = elem.additionalComment
								return add_comm
							})
							const student_feedback_score = (feedback.submission_list as { answers: any; submitted: boolean }[]).map((elem) => {
								let result: any = 0
								if (elem.submitted && elem.answers.length !== 0) {
									result = elem.answers.reduce((a: number, b: number) => a + b) / 7
								} else {
									result = 'Not Submitted'
								}
								return result
							})
							const data = [student_name_list, student_roll_list, student_feedback_score, student_additional_comm_list]
							const transpose = (m: any[][]) => m[0].map((x, i) => m.map((x) => x[i]))
							const data_final = transpose(data)
							data_final.unshift(['Name', 'Username', 'Feedback score out of 5', 'Additional Comments'])
							console.log(data_final)

							exportToCsv(`report_${feedback.feedback.heading}_student_wise.csv`, data_final)
						}}
						className='btn btn-primary download'
					>
						Download Report Student wise
					</div>
					<div>
						Weighted Average : <span className='bold'>{(sum / answers.length / 7).toFixed(2)} </span>
					</div>
					{feedback.submission_list.map((elem, idx) => (
						<>
							{elem.submitted === true && elem.answers.length !== 0 && (
								<div className=''>
									{' '}
									{idx + 1} {elem.first_name} {elem.last_name}:{' '}
									<span className='bold'>{(elem.answers.reduce((a: number, b: number) => a + b) / 7).toFixed(2)}/5</span>
								</div>
							)}

							{elem.submitted !== true && (
								<div className=''>
									{' '}
									{idx + 1} {elem.first_name} {elem.last_name}: <span className='bold'>Not Submitted</span>
								</div>
							)}
						</>
					))}
				</>
			)}
		</>
	)
}

export default FeedbackView
