import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

interface FeedbackInviteProps {
	feedback_id: string
	course: string
}

const FeedbackInvite: React.FC<FeedbackInviteProps> = ({ feedback_id, course }) => {
	const [copied, setCopied] = useState(false)

	const inviteLink = `${window.location.origin}/course/${course}/activities/feedback/${feedback_id}`

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(inviteLink)
			setCopied(true)
			setTimeout(() => setCopied(false), 2000)
		} catch (err) {
			console.error('Failed to copy:', err)
		}
	}

	return (
		<div className='space-y-4'>
			<div className='flex items-center space-x-2'>
				<Button onClick={copyToClipboard} variant='outline' className='flex items-center space-x-2'>
					{copied ? (
						<>
							<span className='mr-2'>✓</span>
							<span>Copied!</span>
						</>
					) : (
						<>
							<span className='mr-2'>📋</span>
							<span>Copy Invite Link</span>
						</>
					)}
				</Button>
			</div>
		</div>
	)
}

export default FeedbackInvite
