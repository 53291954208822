import { navigate, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { Button } from 'react-bootstrap'
import { createFeedback } from '../../../../../API/feedback'
import { exportToCsv } from '../../../../admin/course/[id]'
import Select from 'react-select'
import { MenuItem, Select as MUISelect } from '@material-ui/core'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import { toast } from 'react-toastify'
// import { useGetModulesHook } from 'hooks/useGetModulesHook'
// import { useEditableHook } from 'hooks/useEditableHook'
// import { getFeedback } from 'API/feedback'

const types = [
	{
		name: 'THEORY',
		questions: [
			'LEARNING OBJECTIVES DEFINED AND COMPLETED',
			'PRESENTATION QUALITY',
			'CONTENT QUALITY',
			'DURATION OF CLASS OPTIMAL',
			'STUDENT - TEACHER INTERACTIVITY',
			'ANY ISSUES REGARDING AUDIO-VISUAL/ INFRASTRUCTURE',
			'OVERALL SATISFACTION',
		],
	},
	{
		name: 'PRACTICAL',
		questions: [
			'LEARNING OBJECTIVES DEFINED AND COMPLETED',
			'PRACTICAL MATERIAL AVAILABILITY',
			'WERE YOU SATISFIED FROM THE MODALITY OF PRACTICAL TEACHING',
			'TIME ALLOCATED FOR PRACTICAL OR PROJECT',
			'STUDENT - TEACHER INTERACTIVITY',
			'ANY ISSUES REGARDING AUDIO-VISUAL/ INFRASTRUCTURE',
			'OVERALL SATISFACTION',
		],
	},
	{
		name: 'CLINIC',
		questions: [
			'LEARNING OBJECTIVES DEFINED AND COMPLETED',
			'CLINICAL MATERIAL AVAILABILITY',
			'WERE YOU SATISFIED FROM THE MODALITY OF CLINICAL TEACHING',
			'TIME ALLOCATED FOR CLINICAL COMPETENCY/ SKILL',
			'STUDENT - TEACHER INTERACTIVITY',
			'ANY ISSUES REGARDING AUDIO-VISUAL/ INFRASTRUCTURE',
			'OVERALL SATISFACTION',
		],
	},
]

const Feedback: React.FC<PageProps> = ({ params: { id: course } }) => {
	const { course: courseData } = useGetCourseDetails(course)

	// const { modules } = useGetModulesHook({ course, stable, type_of_course })
	// const { edit, elem_id } = useEditableHook({ location })
	// const [editable, setEditable] = React.useState<any>({})

	// React.useEffect(() => {
	// 	if (edit === 'true' && elem_id !== '') {
	// 		const run = async () => {
	// 			const asset = await getFeedback(elem_id, 'Teacher')
	// 			if (asset) {
	// 				console.log('asset', asset)
	// 				setEditable(asset)
	// 			}
	// 		}
	// 		run()
	// 	}
	// }, [edit, elem_id])

	const [questions, setQuestions] = React.useState<string[]>([])
	// const [innerSelection, setInnerSelection] = React.useState<any[]>([])
	const [selection, setSelection] = React.useState<any>()
	const { ShowDetails, viewAs, type_of_course, stable } = useGetCourseDetails(course)
	const [title, setTitle] = React.useState('')
	const [heading, setHeading] = React.useState('')
	const [type, setType] = React.useState('')

	const { run: getSortedModules } = useGetModulesHook({ course: course, stable, type_of_course })

	const [modules, setModules] = useState<any>([])

	const addQuestion = () => {
		setQuestions((questions) => [...questions, title])
		setTitle('')
	}

	useEffect(() => {
		if (type_of_course && stable) {
			const run = async () => {
				const value = await getSortedModules()
				console.log(value)
				if (value) {
					setModules(value)
				} else {
					setModules([])
				}

				// setModules(await getSortedModules())
			}
			run()
		}
	}, [type_of_course, stable])
	return (
		<>
			{/* <div
				onClick={() => {
					exportToCsv(`${courseData?.course.name}-${heading}-${type}-${Math.floor(Date.now() / 1000)}.csv`, [
						[`Questions`],
						...questions.map((elem) => [elem]),
					])
				}}
				className='btn btn-primary download'
			>
				Download
			</div> */}
			<h1>{courseData?.course.name.toUpperCase()}</h1>
			<input
				type='text'
				onChange={(e) => {
					setHeading(e.target.value)
				}}
				style={{
					padding: '10px',
					minWidth: '80%',
					margin: '10px',
				}}
				value={heading}
				placeholder='Subject'
			/>
			<br />
			<Select
				onChange={(e) => {
					if (e) setQuestions(JSON.parse(e.value))
				}}
				options={types.map((elem) => ({ label: elem.name, value: JSON.stringify(elem.questions) }))}
			/>
			<br />
			<input
				type='text'
				onChange={(e) => {
					setType(e.target.value)
				}}
				style={{
					padding: '10px',
					minWidth: '80%',
					margin: '10px',
				}}
				value={type}
				placeholder='Marker Point'
			/>

			{questions.map((elem, idx) => (
				<div style={{ padding: '10px 10px', maxWidth: '80%', minWidth: '300px', fontWeight: 700 }} key={idx} className=''>
					<div className=''>{idx + 1}. </div>
					<textarea
						name=''
						style={{
							padding: '10px',
							minWidth: '100%',
							margin: '10px',
						}}
						onChange={(e) => {
							const new_questions = [...questions]
							new_questions[idx] = e.target.value
							setQuestions(new_questions)
						}}
						value={elem}
					/>
					<br />
					<div
						onClick={() => {
							const new_questions = [...questions]
							new_questions.splice(idx, 1)
							setQuestions(new_questions)
						}}
						className='btn btn-danger my-3'
					>
						Delete
					</div>
					<div
						onClick={() => {
							// replace n and n-1 element from questions
							if (idx !== 0) {
								// switch idx and idx-1
								const new_questions = [...questions]
								const temp = new_questions[idx]
								new_questions[idx] = new_questions[idx - 1]
								new_questions[idx - 1] = temp
								setQuestions(new_questions)
							}
						}}
						className='btn'
					>
						Move Up
					</div>

					<div
						onClick={() => {
							// replace n and n-1 element from questions
							if (idx !== questions.length - 1) {
								// switch idx and idx-1
								const new_questions = [...questions]
								const temp = new_questions[idx]
								new_questions[idx] = new_questions[idx + 1]
								new_questions[idx + 1] = temp
								setQuestions(new_questions)
							}
						}}
						className='btn'
					>
						Move Down
					</div>
					<hr />
				</div>
			))}
			<textarea
				name=''
				onChange={(e) => {
					setTitle(e.target.value)
				}}
				style={{
					padding: '10px',
					minWidth: '80%',
					margin: '10px',
				}}
				value={title}
			/>
			<Button onClick={addQuestion}>Add Question</Button>
			{/* <div className='addBatch'>
				Add Batch
				<br />
				<textarea
					name=''
					style={{
						padding: '10px',
						minWidth: '80%',
						margin: '10px',
					}}
					onChange={(e) => {
						try {
							setQuestions(JSON.parse(e.target.value))
						} catch (err) {
							console.error(err)
						}
					}}
					value={JSON.stringify(questions)}
				/>
			</div> */}
			<div className='create'>
				<div
					onClick={async () => {
						const feedback = await createFeedback({ questions, course: course, heading, type, module: selection })
						if (feedback) {
							toast('success', {
								type: 'success',
							})
							navigate(`/course/${course}/activities/feedback/${feedback.uid}`)
						} else {
							toast('Subject/Marks can not be blank', {
								type: 'error',
							})
						}
					}}
					className='btn btn-primary'
				>
					Create
				</div>
			</div>
			{modules?.length > 0 && (
				<MUISelect
					displayEmpty
					name={'module'}
					style={{ minWidth: '100%' }}
					labelId={'Module'}
					onChange={(e: any) => {
						// handleChange(e)
						setSelection(e.target.value)
					}}
				>
					<MenuItem selected disabled>
						{'Module'}
					</MenuItem>
					{modules?.map((elem: any, index: number) => {
						return (
							<MenuItem key={index} value={elem.value}>
								{elem.label}
							</MenuItem>
						)
					})}
				</MUISelect>
			)}
		</>
	)
}

export default Feedback
